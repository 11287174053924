<template>
    <div class="flex w-75 max-w-full flex-col overflow-y-auto" ref="el">
        <div
            class="flex cursor-pointer flex-col gap-2.5 border-b border-gray-200 px-6 py-3"
            :class="{
                'bg-primary-50': isSelectedClientTask(clientTask),
            }"
            v-for="clientTask in clientTasks"
            :key="clientTask.id"
            @click="onSelectClientTask(clientTask)"
        >
            <div class="flex items-start justify-between gap-1">
                <div class="flex flex-col overflow-hidden">
                    <p class="truncate text-sm font-medium text-gray-900">
                        {{ clientTask.subject }}
                    </p>
                    <time class="text-xs font-normal text-gray-500">
                        {{ $filters.dateHumanFormat(clientTask.created_at) }}
                    </time>
                </div>
                <base-badge
                    v-if="clientTask.sub_tasks.length > 0"
                    :label="`${totalDoneTasks(clientTask.sub_tasks)}/${
                        clientTask.sub_tasks.length
                    }`"
                    :variant="
                        isSelectedClientTask(clientTask) ? 'primary' : 'gray'
                    "
                    size="sm"
                />
            </div>

            <div
                class="truncate text-sm font-medium text-gray-500"
                v-html="
                    $filters.sanitize(clientTask.room.latest_message.message)
                "
            />
        </div>
    </div>
</template>
<script setup>
import { useInfiniteScroll } from '@vueuse/core'
import {
    useClientTask,
    useClientTasks,
} from '@tenant/modules/accountant/practise-projects/composables/use-client-tasks'
import { totalDoneTasks } from '@tenant/modules/accountant/practise-projects/utils/helper'
import { ECHO_EVENTS } from '@tenant/utils/constants'

const emit = defineEmits(['on-select-client-task'])

const props = defineProps({
    project: {
        type: Object,
        default: null,
    },
    tenantId: {
        type: String,
        required: true,
    },
    clientTask: {
        type: Object,
        default: null,
    },
})

const el = ref(null)

const { clientTasks, onLoadMore } = useClientTasks(props.project?.id)
const { upsertSubTask } = useClientTask(props.project?.id)

useInfiniteScroll(
    el,
    () => {
        onLoadMore()
    },
    { distance: 10 }
)

useListen(
    `global.${props.tenantId}`,
    ECHO_EVENTS.PROJECT_CLIENT_TASKS,
    ({ subTask }) => {
        upsertSubTask(subTask)
    }
)

const onSelectClientTask = (clientTask) => {
    emit('on-select-client-task', clientTask)
}

const isSelectedClientTask = (clientTask) => {
    return clientTask.id === props.clientTask?.id
}
</script>
